import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

const PlayOnVisible = ({ el, children, partialVisibility }) => (
  <VisibilitySensor
    partialVisibility={partialVisibility}
    onChange={(isVisible) => {
      if (isVisible && el.current.animation) {
        el.current.animation.play();
      }
    }}
  >
    {children}
  </VisibilitySensor>
);

export default PlayOnVisible;
