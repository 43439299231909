import React from 'react';

const MenuStatusContext = React.createContext();

const useMenuStatus = () => {
  const context = React.useContext(MenuStatusContext);

  if (!context) {
    throw new Error(`useMenuStatus must be used within a MenuStatusContext.Provider`);
  }

  return context;
};

const MenuStatusProvider = (props) => {
  const [menuStatus, setMenuStatus] = React.useState('closed');
  const value = React.useMemo(() => [menuStatus, setMenuStatus], [menuStatus]);

  return <MenuStatusContext.Provider value={value} {...props} />;
};

export { useMenuStatus, MenuStatusProvider };
