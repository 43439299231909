import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { H2 } from '@components/Typography';
import { useMenuStatus } from '@contexts/MenuStatus';

import blueOrbSemiSrc from '+static/elements/blue-orb-semi.svg';
import tealFinSemiSrc from '+static/elements/teal-fin-semi.svg';
import blueStarSemiSrc from '+static/elements/blue-star-semi.svg';
// import tealArcSemiSrc from '+static/elements/teal-arc-semi.svg';

const menuVariants = {
  open: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
    transitionEnd: {
      display: 'none',
    },
  },
  closed: {
    display: 'flex',
    opacity: 1,
    transition: {
      duration: 0.4,
    },
  },
};

const MENU = [
  {
    label: 'Hackathons',
    link: '/hackathons',
    color: '#cab454',
    bgColor: 'beige',
    patternSrc: blueOrbSemiSrc,
  },
  {
    label: 'About',
    link: '/about',
    color: '#006b39',
    bgColor: 'greenCyan',
    patternSrc: tealFinSemiSrc,
  },
  {
    label: 'Blog',
    link: '/blog',
    color: '#215ae3',
    bgColor: 'sky',
    patternSrc: blueStarSemiSrc,
  },
  // {
  //   label: 'Shop',
  //   link: '/shop',
  //   color: '#134e47',
  //   bgColor: 'teal',
  //   patternSrc: tealArcSemiSrc,
  // },
];

const Menu = styled(motion.div)`
  position: fixed;
  z-index: 9999;
  width: 100%;
  display: none;
  border-radius: 10px;
  box-sizing: border-box;
  height: 100%;
  background-color: rgba(39, 51, 57, 0.85);
  left: 0;
  top: 80px;
  opacity: 0;
`;

const Nav = styled.nav`
  width: 100%;

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  li {
    display: flex;
    padding: 32px 40px;
  }
`;

const StyleListItem = styled.li`
  position: relative;
  background-color: ${(props) => props.theme.color[props.bgColor]};

  h2 {
    color: ${(props) => props.color};
  }

  &:before {
    content: ' ';
    position: absolute;
    background-image: url(${(props) => props.patternSrc});
    right: 0;
    bottom: 0;
    height: 72px;
    background-repeat: no-repeat;
    width: 72px;
    background-position: bottom left;
  }
`;

const Item = ({ link, color, bgColor, patternSrc, children }) => {
  return (
    <a href={link}>
      <StyleListItem color={color} bgColor={bgColor} patternSrc={patternSrc}>
        <H2>{children}</H2>
      </StyleListItem>
    </a>
  );
};

const MobileHeader = () => {
  const [menuStatus] = useMenuStatus();
  const animate = menuStatus === 'closed' ? 'open' : 'closed';

  return (
    <Menu initial="hidden" animate={animate} variants={menuVariants}>
      <Nav>
        <ul>
          {MENU.map((item) => (
            <Item key={item.label} {...item}>
              {item.label}
            </Item>
          ))}
        </ul>
      </Nav>
    </Menu>
  );
};

export default React.memo(MobileHeader);
