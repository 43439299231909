import React from 'react';
import styled from 'styled-components';

import ExternalLink from '@components/ExternalLink';

import telegramSrc from '+static/icons/telegram.svg';
import twitterSrc from '+static/icons/twitter.svg';
import discordSrc from '+static/icons/discord.svg';
import dribbbleSrc from '+static/icons/dribbble.svg';
import githubSrc from '+static/icons/github.svg';
import linkedinSrc from '+static/icons/linkedin.svg';

const SOCIAL = [
  {
    name: 'Telegram',
    icon: telegramSrc,
    url: 'https://t.me/devfolio',
  },
  {
    name: 'Twitter',
    icon: twitterSrc,
    url: 'https://twitter.com/devfolio',
  },
  {
    name: 'Discord',
    icon: discordSrc,
    url: 'https://nsb.dev/discord',
  },
  {
    name: 'Dribbble',
    icon: dribbbleSrc,
    url: 'https://dribbble.com/devfolio',
  },
  {
    name: 'Github',
    icon: githubSrc,
    url: 'https://github.com/devfolioco',
  },
  {
    name: 'Linkedin',
    icon: linkedinSrc,
    url: 'https://www.linkedin.com/company/devfolio/',
  },
];

const Link = styled.li`
  display: flex;
  align-items: center;
  opacity: 0.6;
  transition: opacity 200ms ease-out;

  &:hover {
    opacity: 1;
  }
`;

const Links = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 32px 0;

  ${Link} + ${Link} {
    margin-left: 20px;
  }
`;

const SocialLinks = () => {
  return (
    <Links>
      {SOCIAL.map((social) => (
        <Link key={social.name}>
          <ExternalLink href={social.url}>
            <img key={social.name} src={social.icon} alt={social.name} />
          </ExternalLink>
        </Link>
      ))}
    </Links>
  );
};

export default SocialLinks;
