import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import CopyMailTo from 'react-copy-mailto';

import { H2, P } from '@components/Typography';
import { Section, Container } from '@components/_global';
import ExternalLink from '@components/ExternalLink';
import Logo from '@components/Logo';
import SocialLinks from '@components/SocialLinks';

import { breakpoint } from '@constants';

import heartScribbleSrc from '+static/heart-scribble.png';

const TooltipStyles = { width: 'max-content', maxWidth: 'max-content', padding: '0px 8px' };

const MENU = {
  Community: {
    'Organize a hackathon': '/organize',
    'Explore hackathons': '/hackathons',
    'Code of Conduct': '/code-of-conduct',
    'Brand Assets': 'https://github.com/devfolioco/brand-assets',
  },
  Company: {
    About: () => (
      <Link to="/about" aria-label="About">
        About
      </Link>
    ),
    Blog: '/blog',
    Jobs: '/jobs',
    Changelog: '/changelog',
    Privacy: '/privacy-policy',
    Terms: '/terms-of-use',
  },
  Support: {
    Help: () => (
      <CopyMailTo email="help@devfolio.co" tooltipStyles={TooltipStyles}>
        Help
      </CopyMailTo>
    ),
    'Refund Policy': '/refund-policy',
    Status: 'https://status.devfolio.co',
    'Contact us': () => (
      <CopyMailTo email="hello@devfolio.co" tooltipStyles={TooltipStyles}>
        Contact us
      </CopyMailTo>
    ),
  },
};

// ____________________________________________________________________
// Styles

const StyledFooter = styled.footer`
  position: relative;
  overflow: hidden;
  background-color: #f7f7f8;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;

  & > div:first-child {
    flex-basis: 28em;

    @media screen and (max-width: ${breakpoint.max.lg}) {
      flex-basis: auto;
      margin-top: 48px;
    }
  }

  @media screen and (max-width: ${breakpoint.max.lg}) {
    flex-direction: column-reverse;
  }
`;

export const List = styled.div`
  & ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    height: 100%;
  }

  & > ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    @media screen and (max-width: ${breakpoint.max.lg}) {
      justify-content: flex-start;
    }
  }

  & > ul > li {
    display: flex;
    flex-direction: column;
    flex-basis: calc(25% - (1.333333em * 3));
    margin-right: 2.5em;

    @media screen and (max-width: ${breakpoint.max.xs}) {
      flex-basis: 100%;
      margin-top: 2em;
      margin-right: 0;
    }

    & li {
      margin-top: 8px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  & a {
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    transition: color 200ms ease-out;

    :hover {
      color: #273339;
    }
  }
`;

const Divider = styled.hr`
  margin: 2.67em 0;
  border-top: 1px solid #616b70;

  @media screen and (max-width: ${breakpoint.max.lg}) {
    margin: 1.34em 0;
  }
`;

const PS = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${breakpoint.max.lg}) {
    flex-direction: column;
    align-items: flex-start;

    > p {
      margin-top: 8px;
    }
  }
`;

const Absolute = styled.div`
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;

  path {
    fill: ${(props) => props.theme.color[props.color]};
  }

  > svg {
    position: absolute;
    bottom: 0;
    right: 0;
    min-height: 100%;
  }

  @media screen and (min-width: ${breakpoint.min.lg}) {
    display: block;
  }
`;

const Anchor = styled.a`
  position: relative;
  z-index: 5;
`;

const Background = ({ color = 'beige' }) => {
  return (
    <Absolute style={{ bottom: 0 }} color={color}>
      <svg height="650" viewBox="0 0 1438 650" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M322 399.13C169.075 438.383 49 460.664 0 650H1440V-26.8734L1117 -26.8734C638 -33.974 866 259.496 322 399.13Z" />
      </svg>
    </Absolute>
  );
};

const Footer = ({ bgColor }) => (
  <StyledFooter>
    <Background color={bgColor} />

    <Section css="position: relative">
      <Container>
        <Flex>
          <div>
            <H2>
              We love <span css="color: #81a2ef">software</span> and the{' '}
              <span css="color: #eeb27b">people</span> who build them.
            </H2>

            <SocialLinks />
          </div>

          <List>
            <ul>
              {Object.keys(MENU).map((subMenuName) => (
                <li key={subMenuName}>
                  <P
                    size="small"
                    color="lightGrey"
                    css="font-weight: 700; text-transform: uppercase; background-blend-mode: luminosity"
                  >
                    {subMenuName}
                  </P>
                  <ul>
                    {Object.keys(MENU[subMenuName]).map((navItemName) => {
                      const navItem = MENU[subMenuName][navItemName];

                      return (
                        <li key={navItemName}>
                          <P size="medium" color="black">
                            {typeof navItem === 'function' ? (
                              navItem()
                            ) : (
                              <Anchor href={navItem}>{navItemName}</Anchor>
                            )}
                          </P>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              ))}
            </ul>
          </List>
        </Flex>

        <Divider />

        <PS>
          <Logo height={32} />

          <div css="max-width: 740px">
            <P size="small" color="lightGrey">
              Built at <ExternalLink href="https://2586labs.com">2586 Labs</ExternalLink>. Made
              with&nbsp;
              <span>
                <img src={heartScribbleSrc} alt="Heart" css="margin-bottom: -8px" />
              </span>
              &nbsp;
              <ExternalLink href="https://gatsbyjs.org">Gatsby.js</ExternalLink>,&nbsp;
              <ExternalLink href="https://www.framer.com/motion/">Framer Motion</ExternalLink> and a
              bunch of other libraries that help making beautiful things on the internet possible.
              We are forever in your debt.
            </P>
          </div>
        </PS>

        <Divider style={{ borderColor: 'transparent' }} />

        <P as="small" size="small" color="lightGrey">
          &copy; {new Date().getFullYear()}, NSB Classic PTE LTD
        </P>
      </Container>
    </Section>
  </StyledFooter>
);

export default React.memo(Footer);
