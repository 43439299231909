import { Link } from 'gatsby';
import React, { useEffect, useContext, useState, useRef } from 'react';
import styled, { css } from 'styled-components';

import { A } from '@components/Typography';
import { Section, Container } from '@components/_global';
import Icon from '@components/Icon';
import Logo from '@components/Logo';
import MobileHeader from '@components/MobileHeader';

import IsLoggedInContext from '@contexts/IsLoggedIn';
import { useMenuStatus, MenuStatusProvider } from '@contexts/MenuStatus';
import { breakpoint, phone, tablet } from '@constants';
import { ROUTES } from '@helpers';

// ____________________________________________________________________
// Styles

const StyledSection = styled(Section)`
  padding: 15px 2rem !important;
  z-index: 9999;
  background-color: ${(props) => (props.menuStatus === 'open' ? '#ffffff' : 'transparent')};

  ${(props) =>
    props.sticky &&
    css`
      background-color: white;
      opacity: 0;
      position: fixed;
      top: 0;
      transform: translateY(-100%);
      width: 100%;
      will-change: opacity, transform;

      ${props.animateIn &&
      css`
        opacity: 1;
        transition: all 400ms cubic-bezier(0.25, 0.75, 0.25, 0.95);
        transform: translateY(0);
      `};

      ${!props.animateIn &&
      css`
        opacity: 0;
        transition: all 200ms cubic-bezier(0.35, 0, 1, 1);
        transform: translateY(-100%);
      `};
    `};

  ${phone} {
    padding: 21px 2rem !important;
  }
`;

const Nav = styled.nav`
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  &:last-child > ul {
    justify-content: flex-end;
  }

  ul li {
    display: inline;
    text-align: center;
  }

  ul li + li {
    margin-left: 1.333333em;

    @media screen and (max-width: ${breakpoint.max.sm}) {
      margin-left: 1em;
    }
  }

  @media screen and (max-width: ${breakpoint.max.sm}) {
    display: none;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;

  ${phone} {
    display: none;
    grid-template-columns: 1fr;
    justify-items: center;
  }

  ${tablet} {
    > nav:nth-child(2) {
      display: none;
    }

    grid-template-columns: 120px 1fr;
  }
`;

const MobileGrid = styled.div`
  display: none;

  ${phone} {
    display: flex;
    align-items: center;
  }

  > div:last-child {
    flex: 1;
    justify-content: flex-end;
  }
`;

const Button = styled.button`
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  outline: none;

  > svg {
    color: #3770ff;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const Phone = styled.div`
  display: none;

  ${phone} {
    display: inherit;
  }
`;

const preventEventDefault = (event) => event.preventDefault();

const HeaderComponent = React.memo((props) => {
  const isLoggedIn = useContext(IsLoggedInContext);
  const [menuStatus, setMenuStatus] = useMenuStatus();
  const headerRef = useRef(null);

  return (
    <StyledSection menuStatus={menuStatus} ref={headerRef} {...props}>
      <Container>
        <MobileGrid menuStatus={menuStatus}>
          <div>
            <Link to={isLoggedIn ? '/home' : '/'} aria-label="Homepage">
              <Logo />
            </Link>
          </div>

          <div style={{ width: 'max-content', display: 'flex', gap: '16px', alignItems: 'center' }}>
            <A appearance="link" href={ROUTES.SIGN_IN}>
              Sign in
            </A>
            <Button
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={() => {
                // Scroll to ensure that the header touches the top of the window.
                if (headerRef.current != null) {
                  const headerRect = headerRef.current.getBoundingClientRect();
                  const yOffset = window.pageYOffset + headerRect.y;
                  window.scrollTo(0, yOffset);
                }

                // Toggle the button icon and set the menu state to render navigation.
                if (menuStatus === 'closed') {
                  setMenuStatus('open');
                  document.body.style.overflowY = 'hidden';
                  document.body.addEventListener('touchmove', preventEventDefault, {
                    passive: false,
                  });
                } else {
                  document.body.removeEventListener('touchmove', preventEventDefault);
                  document.body.style.overflowY = 'auto';
                  setMenuStatus('closed');
                }
              }}
            >
              {menuStatus === 'open' && <Icon name="x" css="color: #fff" />}
              {menuStatus === 'closed' && <Icon name="menu" css="color: #fff" />}
            </Button>
          </div>
        </MobileGrid>

        <Grid>
          <div>
            <Logo isLoggedIn={isLoggedIn} />
          </div>

          <Nav>
            <ul>
              <li>
                <A href="/hackathons">Hackathons</A>
              </li>
              <li>
                <Link to="/about" aria-label="About">
                  <A>About</A>
                </Link>
              </li>
              <li>
                <A href="/blog">Blog</A>
              </li>
            </ul>
          </Nav>

          <Nav>
            <ul>
              <li>
                <A appearance="link" href={ROUTES.ORGANIZE}>
                  Organize a hackathon
                </A>
              </li>
              <li>
                {isLoggedIn ? (
                  <A appearance="link" href={ROUTES.DASHBOARD}>
                    Go to dashboard
                  </A>
                ) : (
                  <A appearance="link" href={ROUTES.SIGN_IN}>
                    Sign in
                  </A>
                )}
              </li>
            </ul>
          </Nav>
        </Grid>
      </Container>
    </StyledSection>
  );
});

const Header = () => {
  const [animateIn, setAnimateIn] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const hero = document.querySelector('#hero') || document.querySelector('#hero-team');

      const navbarHeight = 96;
      const fixedNavActivePosition =
        (hero.offsetTop + hero.offsetHeight - navbarHeight) * (window.devicePixelRatio || 1);

      const handleScroll = () => {
        const top = window.pageYOffset || document.documentElement.scrollTop;

        if (top > fixedNavActivePosition) {
          if (!animateIn) {
            setAnimateIn(true);
          }
        } else {
          if (animateIn) {
            setAnimateIn(false);
          }
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [animateIn]);

  return (
    <MenuStatusProvider>
      <HeaderComponent />
      <HeaderComponent animateIn={animateIn} sticky />
      <Phone>
        <MobileHeader />
      </Phone>
    </MenuStatusProvider>
  );
};

export default React.memo(Header);
