import React from 'react';
import Cookies from 'js-cookie';

/**
 * Check whether the user is authenticated or not by checking
 * the devoflio_user cookie
 */
export const isAuthenticated = () => {
  if (typeof window === 'object') {
    const data = Cookies.get('devfolio_user');
    if (typeof data === 'string') {
      try {
        const parsedData = JSON.parse(atob(data));
        // Check if the parsed data has uuid to see if the user is authenticated
        return !!parsedData.uuid;
      } catch (error) {
        return false;
      }
    }
    return false;
  }
  return false;
};

const IsLoggedInContext = React.createContext(isAuthenticated());

export const IsLoggedInProvider = IsLoggedInContext.Provider;
export const IsLoggedInConsumer = IsLoggedInContext.Consumer;

export default IsLoggedInContext;
